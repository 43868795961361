import Card from "./Card";
import { Avatar, Box, Chip, Grid, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APIClient } from "../../../../shared/Utils/api-client";
import { API_ROUTE_PRESCRIPTION_RECOMMENDATION } from "../../../../shared/routes/route.constants";

const StyledChip = styled(Chip)({
  color: "#494E9D",
  backgroundColor: "#F2F3FF",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "18px",
  letterSpacing: "0.16px",
  cursor: "pointer",
});

const recommendedSections = "DIAGNOSIS,MEDICATION,INVESTIGATIONS";

const DoctorSuggestions = (props: any) => {
  const {
    prescription: { doctorSuggestions, rxData },
  } = useSelector((state: any) => state.prescription);

  const sKey = props?.sKey;
  const sHeading = props?.sHeading;
  const headerImg = props?.headerImg;
  const handleClick = props?.handleAdd;
  const clickSource = props?.clickSource;
  const clickAction = props?.clickAction;

  const [recommendationOptions, setRecommendationOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (recommendedSections.toLowerCase().includes(sKey.toLowerCase())) {
      getRecommendation();
    }
  }, [rxData]);

  const getRecommendation = async () => {
    //type = comp_diag, diag_drug, diag_inv

    setLoader(true);

    let type = "";
    const diagnosis = rxData
      ?.find((elem: any) => elem?.view_type === "DIAGNOSIS")
      ?.rows[0]?.value?.map((val: any) => {
        return { id: val?.id, name: val?.name };
      });
    const drugs = rxData
      ?.find((elem: any) => elem?.view_type === "MEDICATION")
      ?.rows[0]?.value?.map((val: any) => {
        return { id: val?.id, name: val?.name };
      });
    const investigations = rxData
      ?.find((elem: any) => elem?.view_type === "INVESTIGATIONS")
      ?.rows[0]?.value?.map((val: any) => {
        return { id: val?.id, name: val?.name };
      });
    const complaints = rxData
      ?.find((elem: any) => elem?.view_type === "COMPLAINTS")
      ?.rows[0]?.value?.map((val: any) => {
        return { id: val?.id, name: val?.name };
      });


      
    switch (sKey) {
      case "medication":
        if (!diagnosis.length) {
          setLoader(false);
          return;
        }
        type = "diag_drug";
        break;
      case "complaints":
        type = "comp_diag";
        break;
      case "investigations":
        if (!diagnosis.length) {
          setLoader(false);
          return;
        }
        type = "diag_inv";
        break;
      case "diagnosis":
        type = "comp_diag";
        break;
      default:
        break;
    }

    //body
    const body = {
      type,
      diagnosis,
      drugs,
      investigations,
      complaints,
    };

    APIClient.post(API_ROUTE_PRESCRIPTION_RECOMMENDATION(), body)
      .then((res) => {
        setRecommendationOptions(res?.data?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        console.log("error in fetching recommendations", err);
        setLoader(false);
      });
  };

  const shouldShowRecommendations = () => {
    switch (sKey) {
      case "medication":
        return false;
      case "complaints":
        return false;
      case "investigations":
        return true;
      case "diagnosis":
        return true;
      default:
        return false;
    }
  };

  return (
    <Card styles={{ padding: 0 }}>
      {doctorSuggestions && Array.isArray(doctorSuggestions[sKey]) ? (
        <Box>
          <Box display={"flex"} gap={"13px"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                flex: 1,
                width: "50%",
              }}
            >
              <img
                style={{ marginRight: "12px" }}
                src={headerImg}
                alt="icon_dummy"
              />
              <span
                style={{
                  color: "#494E9D",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: "-0.28px",
                  lineHeight: "19px",
                  marginRight: "12px",
                }}
              >
                {sHeading}
              </span>
            </Box>
            <Box
              sx={{
                display: shouldShowRecommendations() ? "flex" : "none",
                alignItems: "center",
                mb: 2,
                flex: 1,
                width: "50%",
              }}
            >
              <img
                style={{ marginRight: "12px" }}
                src={headerImg}
                alt="icon_dummy"
              />
              <span
                style={{
                  color: "#494E9D",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: "-0.28px",
                  lineHeight: "19px",
                  marginRight: "12px",
                }}
              >
                {sHeading?.replace("Suggested", "Recommended")}
              </span>
            </Box>
          </Box>
          <Grid container gap={"13px"}>
            <Box display={"flex"} width={"100%"} gap={"13px"}>
              <Box
                height={"fit-content"}
                width={shouldShowRecommendations() ? "50%" : "100%"}
                flex={1}
                display={"flex"}
                gap={"13px"}
                flexWrap={"wrap"}
              >
                {doctorSuggestions &&
                  Array.isArray(doctorSuggestions[sKey]) &&
                  doctorSuggestions[sKey].filter((item : any)=>props?.data?.every((obj : any)=>obj.name !== item?.name)).map((suggestion: any) => {
                    const sName = suggestion?.name;
                    if (sName)
                      return (
                        <StyledChip
                          key={sName}
                          data-key={sName}
                          data-action={clickAction}
                          aria-label={clickSource}
                          onClick={handleClick}
                          avatar={<Avatar alt="" src="/images/icon_add.svg" />}
                          label={sName}
                        />
                      );
                  })}
              </Box>
              <Box
                width={shouldShowRecommendations() ? "50%" : "100%"}
                height={"fit-content"}
                display={shouldShowRecommendations() ? "flex" : "none"}
                flex={1}
                flexWrap={"wrap"}
                gap={"13px"}
              >
                {loader ? (
                  <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                    <Skeleton animation={"wave"} />
                    <Skeleton animation={"wave"} />
                    <Skeleton animation={"wave"} />
                    <Skeleton animation={"wave"} />
                  </Box>
                ) : (
                  <>
                    {recommendationOptions?.length &&
                    Array.isArray(recommendationOptions) ? (
                      recommendationOptions.map((suggestion: any) => {
                        const sName = suggestion?.name;
                        if (sName)
                          return (
                            <StyledChip
                              key={sName}
                              data-key={sName}
                              data-action={clickAction}
                              aria-label={clickSource}
                              onClick={handleClick}
                              avatar={
                                <Avatar alt="" src="/images/icon_add.svg" />
                              }
                              label={sName}
                            />
                          );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Box>
      ) : (
        "Type to get suggestions"
      )}
    </Card>
  );
};

export default DoctorSuggestions;
